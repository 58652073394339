import { patch, post } from 'utils/axiosHandler'
let getCustomerByIdSource
export const getCrmList = async (data, options) => {
  return post('/crm/customer/list', data, options)
}

export const getCustomerById = (id) => async (_dispatch, getState) => {
  const {
    stores: { selectedStores },
  } = getState()

  if (getCustomerByIdSource) {
    getCustomerByIdSource.abort()
  }

  getCustomerByIdSource = new AbortController()
  const signal = getCustomerByIdSource.signal

  return await post(
    '/crm/customer/detail',
    {
      id,
      storeIds: selectedStores,
    },
    {
      signal,
    }
  )
}

export const getCustomerDetails = async (body) =>
  await post('/crm/customer/detail', body)

export const postCustomer = async (data) => {
  if (data?._id) {
    const customerId = data._id
    delete data._id
    return patch(`/crm/customer/${customerId}`, data)
  } else {
    return post('/crm/customer', data)
  }
}
