import { toast } from 'react-toastify'
import { errorHandler } from './errorHandler'
import { toggleLoader } from 'redux/actions/layout'
import axios from 'axios'
import { host } from 'config/config'
import { store } from 'redux/index'

const getToken = () => localStorage.getItem('token')
const axiosInstance = axios.create({
  baseURL: host,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
})

const validateStatus = (status) => {
  return status >= 200 && status < 400
}

export const post = (path, data, options = {}) => {
  if (options.loader) {
    store.dispatch(toggleLoader(true))
  }
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(path, data, {
        ...options,
        headers: {
          Authorization: 'Bearer ' + getToken(),
          ...options.headers,
        },
        validateStatus: validateStatus,
      })
      .then((res) => {
        if (options.successMessage && res.data.code) {
          toast.success(options.successMessage)
        }
        if (options.loader) store.dispatch(toggleLoader(false))
        resolve(res.data)
      })
      .catch((e) => {
        if (options.loader) store.dispatch(toggleLoader(false))
        e.response &&
          errorHandler(
            e && e.response && e.response.status,
            options.errorMessage ? options.errorMessage : `Error Occurred ${e}`
          )
        reject(e)
      })
  })
}

export const get = (path, data, options = {}) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(path, {
        ...options,
        params: data,
        headers: {
          Authorization: 'Bearer ' + getToken(),
          ...options.headers,
        },
        validateStatus: validateStatus,
      })
      .then((res) => {
        if (options.successMessage && res.data.code) {
          toast.success(options.successMessage)
        }
        resolve(res.data)
      })
      .catch((e) => {
        e.response &&
          errorHandler(
            e.response.status,
            options.errorMessage ? options.errorMessage : `Error Occurred ${e}`
          )
        reject(e)
      })
  })
}

export const put = (path, data, options = {}) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(path, data, {
        ...options,
        headers: {
          Authorization: 'Bearer ' + getToken(),
          ...options.headers,
        },
        validateStatus: validateStatus,
      })
      .then((res) => {
        if (options.successMessage && res.data.code) {
          toast.success(options.successMessage)
        }
        resolve(res.data)
      })
      .catch((e) => {
        e.response &&
          errorHandler(
            e.response.status,
            options.errorMessage ? options.errorMessage : `Error Occurred ${e}`
          )
        reject(e)
      })
  })
}

export const patch = (path, data, options = {}) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .patch(path, data, {
        ...options,
        headers: {
          Authorization: 'Bearer ' + getToken(),
          ...options.headers,
        },
        validateStatus: validateStatus,
      })
      .then((res) => {
        if (options.successMessage && res.data.code) {
          toast.success(options.successMessage)
        }
        resolve(res.data)
      })
      .catch((e) => {
        e.response &&
          errorHandler(
            e.response.status,
            options.errorMessage ? options.errorMessage : `Error Occurred ${e}`
          )
        reject(e)
      })
  })
}

export const deleteReq = (path, data, options = {}) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(path, {
        data,
        ...options,
        headers: {
          Authorization: 'Bearer ' + getToken(),
          ...options.headers,
        },
        validateStatus: validateStatus,
      })
      .then((res) => {
        if (options.successMessage && res.data.code) {
          toast.success(options.successMessage)
        }
        resolve(res.data)
      })
      .catch((e) => {
        e.response &&
          errorHandler(
            e.response.status,
            options.errorMessage ? options.errorMessage : `Error Occurred ${e}`
          )
        reject(e)
      })
  })
}

export const serviceRequest = ({ path, data, method, options }) => {
  return axiosInstance[method](path, data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
      ...options?.headers,
    },
    validateStatus: validateStatus,
  })
}
