import React, { useRef } from 'react'
import { useTheme } from 'styled-components'

import { useSelectStore } from 'hooks'
import Navbar from 'components/Layout/Navbar'
import {
  SectionHeading,
  Button,
  useToasts,
  UploadExcel as UploadExcelSteps,
  Text,
} from 'components/Common'
import { spacing } from 'design-system'
import { post } from 'utils/axiosHandler'
import { $Upload, $InformationBox } from '../CRM.styled'
import { ERROR_DECODE } from 'components/Content/autoEngagement/constants'
import { FlexBox } from 'styledComponent'
import { isIframe } from 'utils/iframeUtils'

export const UploadExcel = () => {
  const [file, setFile] = React.useState(null)
  const [isUploading, setIsUploading] = React.useState(false)
  const [selectedVendor, setSelectedVendor] = React.useState({})

  const { toast } = useToasts()
  const { data: vendorIds } = useSelectStore('vendorIds.vendorIds')
  const isSubmitDisabled = !file || isUploading

  const fileRef = useRef(null)

  React.useEffect(() => {
    setSelectedVendor(vendorIds[0])
  }, [])

  const handleVendorChange = (value) => {
    setSelectedVendor(value)
  }

  const onFileChange = (e) => {
    e.preventDefault()

    setFile(e.target.files[0])
  }

  const resetFile = () => {
    setFile(null)
    if (fileRef?.current?.value) {
      fileRef.current.value = ''
    }
  }

  const onFileSave = async (e) => {
    e.preventDefault()

    const formData = new FormData()
    formData.append('file', file)

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    try {
      setIsUploading(true)
      const res = await post(
        `/crm/customer/vendor/${selectedVendor?.value}/bulk-upload`,
        formData,
        config
      )
      if (res.code) {
        toast('Your file is uploaded successfully!', 'success')
        setIsUploading(false)
        resetFile()
      }
    } catch (e) {
      toast(ERROR_DECODE(e), 'error')
      console.log('Error in uploading file', e)
    } finally {
      setIsUploading(false)
    }
  }

  const onCancel = (e) => {
    e.preventDefault()
    if (file) resetFile()
  }

  return (
    <>
      {!isIframe() ? <Navbar heading="CRM" /> : null}
      <FlexBox>
        <$Upload>
          <SectionHeading
            heading="Add consumer data"
            subHeading="Follow the below 4 steps to add user data manually"
            variant="medium"
            subVariant="small"
          />
          <UploadExcelSteps ref={fileRef} onFileChange={onFileChange} />
          <Button
            type="submit"
            variant="primary"
            mt={spacing.l}
            mx={spacing.m}
            onClick={onFileSave}
            disabled={isSubmitDisabled}
          >
            Import Data
          </Button>
          {file && (
            <Button type="button" variant="textualPrimary" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </$Upload>
        <$InformationBox>
          <div>
            <Text type="main" variant="p" weight="bold" mb={spacing.m}>
              Instructions:
            </Text>
            <ol>
              <li>
                The sample excel contains all headers relating to customer data
                upload.
              </li>
              <li>
                If you do not have certain data points for some customers you
                can leave that cell blank, avoid putting in wrong data to
                maintain data quality.
              </li>
              <li>
                Please make sure you do not delete any headers or change their
                position or their text in the Sample CSV file.
              </li>
              <li>
                You can rename the file if you want to but do not change the
                file extension, only .CSV files are accepted.
              </li>
              <li>Add the phone number without the country code.</li>
              <li>Gender options - male, female, other (No caps).</li>
              <li>Marital Status options - married, not married (No caps).</li>
              <li>
                D.O.B &amp; anniversary date format - MM/DD/YYYY OR DD/MM/YYYY.
              </li>
              <li>City &amp; State - Enter without capital letters.</li>
              <li>
                Capital letters allowed in Names, profession, company name and
                Spouse names.
              </li>
            </ol>
          </div>
        </$InformationBox>
      </FlexBox>
    </>
  )
}
