import config from 'config/config'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

const RouterChangeComponent = () => {
  const location = useLocation()
  const auth = useSelector((state) => state.auth)
  useEffect(() => {
    if (window.parent && window.parent.postMessage)
      window.parent.postMessage(
        {
          type: 'locationChange',
          payload: {
            location: location.pathname,
            isAuthenticated: auth.isAuthenticated,
          },
        },
        config.rzpDomain
      )
  }, [location, auth])
  return <></>
}

export default RouterChangeComponent
