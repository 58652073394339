import React from 'react'
import bmFeedbackScreen3 from 'images/feedback/billme-illus-feedback-screen3.svg'
import { CampaignNameContainer } from 'styledComponent/components/modal'
import { FlexBox, FloatingInput, GreyText, ErrorMessage } from 'styledComponent'

const CampaignScreenThree = ({
  brandLogo,
  brandName,
  campaignName,
  handleCampaignName,
  stores,
  hasError,
}) => {
  function getStoresList() {
    const storeCount = stores.length

    if (storeCount <= 4) {
      return stores.join(', ')
    } else if (storeCount > 4) {
      let store = ''

      for (let i = 0; i < 3; i++) {
        store += stores[i] + ', '
      }
      store += stores[3]

      return (
        <>
          {store} <GreyText as="span">+{storeCount - 4} more</GreyText>
        </>
      )
    }
  }

  return (
    <div style={{ padding: '1em 30px', background: '#fff' }}>
      <h5>Step 3/3: Name your Campaign</h5>
      <ErrorMessage as="span" error={hasError}>
        Please provide a campaign name
      </ErrorMessage>
      <CampaignNameContainer>
        <section>
          <FlexBox align="center">
            <img
              src={brandLogo}
              alt={`${brandName} logo`}
              style={{
                width: '65px',
                height: '65px',
                borderRadius: '50%',
              }}
            />
            <FloatingInput>
              <input
                type="text"
                value={campaignName}
                onChange={handleCampaignName}
              />
              <label>Campaign Name</label>
            </FloatingInput>
          </FlexBox>
          <p>
            <span>Brand</span>
            <br />
            {brandName}
          </p>
          <p style={{ wordBreak: 'break-word' }}>
            <span>Stores</span>
            <br />
            {stores && stores.length && getStoresList()}
          </p>
        </section>
        <div>
          <img src={bmFeedbackScreen3} alt="BillMe Feedback illustration" />
        </div>
      </CampaignNameContainer>
    </div>
  )
}

export default CampaignScreenThree
