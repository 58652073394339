import React, { useEffect, useState } from 'react'

import Navbar from 'components/Layout/Navbar'
import { spacing } from 'design-system'
import { Breadcrumb, RenderIf, Text } from 'components/Common'
import {
  $BodyStyles,
  $FlexBox,
  $SelectContainer,
  $Container,
} from './UserComplaints.styled'
import ComplaintsListing from './ComplaintsList'
import { useSelectStore, useVendorSelect } from 'hooks'
import { FlexBox } from 'styledComponent'
import { StoreHeading } from 'components/Layout/StoresHeader'
import { SimpleOutlineButton } from 'styledComponent/components/button'
import { $MarginlessLine } from '../autoEngagement/AutoEngagement.styled'
import { isIframe } from 'utils/iframeUtils'

const UserComplaints = () => {
  const {
    data: { vendorIds, selectedVendor },
  } = useSelectStore('vendorIds')

  const { handleVendorChange } = useVendorSelect()

  const [isPopOpen, setIsPopOpen] = useState(false)
  const [selectedStoreIds, setSelectedStoreIds] = useState([])
  const [storeIds, setStoreIds] = React.useState([])

  useEffect(() => {
    if (!isPopOpen) setStoreIds(selectedStoreIds)
  }, [isPopOpen])

  return (
    <>
      <RenderIf active={!isIframe()}>
        <Navbar heading="Customer Complaints" />
      </RenderIf>
      <FlexBox m={spacing.l} justify="space-between">
        <RenderIf active={!isIframe()}>
          <Breadcrumb label="Customer Complaints Listing" />
        </RenderIf>
        <SimpleOutlineButton
          type="button"
          className="py-2"
          onClick={() => setIsPopOpen(true)}
        >
          <StoreHeading
            selectedStoreIds={selectedStoreIds}
            setSelectedStoreIds={setSelectedStoreIds}
            isPopOpen={isPopOpen}
            setIsPopOpen={setIsPopOpen}
          />
        </SimpleOutlineButton>
      </FlexBox>
      <$MarginlessLine />
      <$Container padding={spacing.l}>
        <ComplaintsListing storeIds={storeIds} setStoreIds={setStoreIds} />
      </$Container>
      <$BodyStyles />
    </>
  )
}

export default UserComplaints
