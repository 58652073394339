import React, { Component } from 'react'
import { IntlProvider } from 'react-intl'

import Login from './components/Auth/Login.js'
import Confirmation from './components/Auth/Confirmation.js'
import Wrapper from './components/Layout/Wrapper'
import AddContact from './components/Auth/UpdatePhoneNo'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { ToastProvider } from 'components/Common'
import { persistor, store } from './redux'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { Default } from './styledComponent'
import AppHOC from 'components/Common/AppHOC.jsx'
import SSO from 'components/Content/SSO'
import RouterChangeComponent from 'components/RouterChangeComponent.jsx'

// toast.configure({
// 	position: "bottom-right",
// 	autoClose: 5000,
// 	closeOnClick: true,
// 	rtl: false,
// 	draggable: false,
// 	pauseOnHover: true,
// });

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <IntlProvider locale="en-GB">
            <ThemeProvider theme={Default}>
              <ToastProvider>
                <AppHOC>
                  <div className="App">
                    <Router>
                      <RouterChangeComponent />
                      <Switch>
                        <Route exact path="/login" component={Login} />
                        {/* <Route exact path="/signup" component={Signup} /> */}
                        <Route
                          exact
                          path="/addContact"
                          component={AddContact}
                        />
                        <Route
                          exact
                          path="/confirmation"
                          component={Confirmation}
                        />
                        <Route exact path="/sso" component={SSO} />
                        <Route path="/" component={Wrapper} />
                      </Switch>
                    </Router>
                  </div>
                </AppHOC>
              </ToastProvider>
            </ThemeProvider>
          </IntlProvider>
        </PersistGate>
      </Provider>
    )
  }
}

export default App
