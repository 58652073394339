import _ from 'lodash'
import { post, get } from 'utils/axiosHandler'
import { getCompaniesHelper } from './payments'
import { setSelectedVendor, setVendorIdsAvailable } from './vendorActions'
import { setAvailableBrands } from './vendorActions'
import { getRootDomain } from 'utils/urlUtil'
import { deleteCookie } from 'utils/cookie'
import { isIframe } from 'utils/iframeUtils'

export const postLoginSuccess = (data) => (dispatch, getState) => {
  //set token
  localStorage.setItem('token', data.token)
  forwardDisatch(dispatch)(data)
  if (data?.companiesArray?.length) {
    dispatch(getCompaniesHelper(data.companiesArray))
    const selectedStores = getState().stores.selectedStores
    const stores = getState().stores.stores
    let companiesId = new Set()
    for (let storeId of selectedStores) {
      stores[storeId] && companiesId.add(stores[storeId].companyId)
    }
    dispatch(setSelectedCompanies(Array.from(companiesId)))
  }

  let vendorLength = getState().vendorIds.vendorIds.length
  if (vendorLength < 2) {
    let selectedStores = getState().stores.selectedStores
    if (selectedStores.length) {
      const stores = getState().stores.stores
      let brandIds = new Set()
      for (let storeId of selectedStores) {
        stores[storeId] && brandIds.add(stores[storeId].brandId)
      }
      dispatch(setSelectedBrands(Array.from(brandIds)))
      dispatch(setSelectedVendor(getState().vendorIds.vendorIds[0]))
    }
  }
}

//Login get user
export const loginUser = (userData) => {
  return post('/auth/loginViaOTP', userData)
}

export const verifyOTP = (userData, options) => {
  return post('/auth/verifyOTP', userData, options)
}

export const verifyCustomOTP = (userData) => {
  return post('/auth/verifyCustomOTP', userData)
}

export const updatePhone = (userData) => {
  return post('/user/updatePhone', userData)
}

export const checkReadPermission = (moduleName) => (dispatch, getState) => {
  const stores = getState().stores.stores
  const storeIds =
    (getState().stores.selectedStores.length &&
      getState().stores.selectedStores) ||
    Object.keys(stores)
  for (let storeId of storeIds)
    if (
      stores[storeId]?.isAdmin ||
      stores[storeId]?.permissions[moduleName] === 'w' ||
      stores[storeId]?.permissions[moduleName] === 'r'
    )
      return true
  return false
}

export const checkWritePermission = (moduleName) => (dispatch, getState) => {
  const stores = getState().stores.stores
  const storeIds =
    (getState().stores.selectedStores.length &&
      getState().stores.selectedStores) ||
    Object.keys(stores)
  for (let storeId of storeIds)
    if (
      stores[storeId].isAdmin ||
      stores[storeId].permissions[moduleName] === 'w'
    )
      return true
  return false
}

export const setSelectedStore = (params) => async (dispatch, getState) => {
  const data = await post('/store/selectStores', params)
  if (data.code) {
    dispatch(setSelectedStoresHelper(data.selectedStores))
    const selectedStores = getState().stores.selectedStores
    const stores = getState().stores.stores
    let brandIds = new Set()
    let companiesId = new Set()
    for (let storeId of selectedStores) {
      stores[storeId] &&
        brandIds.add(stores[storeId].brandId) &&
        companiesId.add(stores[storeId].companyId)
    }
    dispatch(setSelectedBrands(Array.from(brandIds)))
    dispatch(setSelectedCompanies(Array.from(companiesId)))
  }
}

export const forwardDisatch = (dispatch) => {
  return setStoreAndAuth(dispatch)
}
export const setStoreAndAuth = (dispatch) => (data) => {
  const { token } = data
  if (data.code) {
    // Set current user
    dispatch(
      setCurrentUser({
        userId: data.userId,
        name: data.name,
        email: data.email,
        phone: data.phone,
        userType: data.userType,
        storeLogin: data.storeLogin,
        currencyCode: data.currencyCode || 'INR',
        ts: data.ts,
      })
    )

    const getBrandInfo = (brandId) =>
      data?.brands?.find((br) => br?.brandId === brandId)

    const allStores = { ...data.stores }
    const stores = { ...allStores }
    const storesArray = Object.values(stores)?.map((str) => ({
      ...str,
      ...getBrandInfo(str?.brandId),
    }))

    let inActiveStores = []
    const inactiveStores = {}

    for (let value in stores) {
      if (!stores[value].isActive) {
        inActiveStores.push(value)
        inactiveStores[value] = stores[value]
        delete stores[value]
      }
    }

    const selectedStores = [...data.selectedStores]?.filter(
      (str) => !inActiveStores?.includes(str)
    )

    dispatch(setBrandsHelper(data.brands))
    dispatch(setAllStoresHelper(allStores))
    dispatch(setInactiveStoresHelper(inactiveStores))
    dispatch(setSetStoresHelper(stores))
    dispatch(setSetStoresArrayHelper(storesArray))
    dispatch(setSelectedStoresHelper(selectedStores))
    dispatch(setVendorIdsAvailable(data.vendors))
    dispatch(setAvailableBrands(data.brands))
  }
}

//Filtering Brand with selected Brand Id
export const filterBrand = (state, moduleName) => {
  const { selectedBrand, brands } = state.brand
  const { stores } = state.stores
  let filteredBrands = brands
  if (selectedBrand.length > 0)
    filteredBrands = brands.filter(
      (brand) => selectedBrand.indexOf(brand.brandId) > -1
    )

  return filteredBrands.filter((brand) => {
    for (let storeId in stores) {
      if (
        stores[storeId].brandId == brand.brandId &&
        (stores[storeId].isAdmin || stores[storeId]['permissions'][moduleName])
      ) {
        return true
      }
    }
    return false
  })
}

//Filtering Companies with selected Companies Id
export const filterCompanies = (state, moduleName) => {
  const companyIds = state.company.selectedCompanies
  const stores = state.stores.stores
  const companies = state.company.companies
  let filteredCompanies = companies

  if (companyIds.length > 0)
    filteredCompanies = companies.filter(
      (company) => companyIds.indexOf(company.companyId) > -1
    )
  return filteredCompanies.filter((company) => {
    for (let storeId in stores) {
      if (
        stores[storeId].companyId == company.companyId &&
        (stores[storeId].isAdmin || stores[storeId]['permissions'][moduleName])
      ) {
        return true
      }
    }
    return false
  })
}

//Filtering Stores with selected store Id
export const filterStores = (state, moduleName) => {
  const { selectedStores } = state.stores
  const stores = _.cloneDeep(state.stores.stores)

  let storeObj = {}
  for (let storeId of selectedStores) stores[storeId].isSelected = true
  for (let storeId in stores) {
    if (
      ((selectedStores.length && stores[storeId].isSelected) ||
        !selectedStores.length) &&
      (stores[storeId].isAdmin || stores[storeId]['permissions'][moduleName])
    ) {
      storeObj[storeId] = stores[storeId]
    }
  }
  return storeObj
}

//set logged in users
export const setCurrentUser = (decoded) => {
  return {
    type: 'SET_CURRENT_USER',
    payload: decoded,
  }
}

export const setLoyaltyId = (data) => {
  return {
    type: 'SET_LOYALTY_ID',
    payload: data,
  }
}

//set companies
export const setCompanies = (data) => {
  return {
    type: 'SET_COMPANIES',
    payload: data,
  }
}

//set default stores
export const setSelectedStoresHelper = (data) => {
  return {
    type: 'SET_SELECTED_STORES',
    payload: data,
  }
}

//set brands
export const setBrandsHelper = (data) => {
  return {
    type: 'SET_BRANDS',
    payload: data,
  }
}

//set stores
export const setSetStoresHelper = (data) => {
  return {
    type: 'SET_STORES',
    payload: data,
  }
}

//set stores array
export const setSetStoresArrayHelper = (data) => {
  return {
    type: 'SET_STORES_ARRAY',
    payload: data,
  }
}

//set all stores
export const setAllStoresHelper = (data) => {
  return {
    type: 'SET_ALL_STORES',
    payload: data,
  }
}

//set all stores
export const setInactiveStoresHelper = (data) => {
  return {
    type: 'SET_INACTIVE_STORES',
    payload: data,
  }
}

//set selected brand
export const setSelectedBrands = (data) => {
  return {
    type: 'SET_SELECTED_BRANDS',
    payload: data,
  }
}

//set selected companies
export const setSelectedCompanies = (data) => {
  return {
    type: 'SET_SELECTED_COMPANIES',
    payload: data,
  }
}

//log user out
export const logoutUser = (queryString, isSso) => async (dispatch) => {
  try {
    await get(`/logout`)
    //set current user to {} which will set isAuthenticated as false (isEmpty)
    dispatch(setCurrentUser({}))
    dispatch(logoutHelper({}))
    localStorage.clear()
    // Done so that user is not stuck in endless loop
    if (!isIframe()) sessionStorage.setItem('ssoRedirected', true)
    localStorage.removeItem('SSO-Token')
    deleteCookie('L-Token')
    if (queryString) {
      if (isSso) ssoTokenHelper(queryString)
      else lTokenHelper(queryString)
    }
    window.location.href = `/login`
  } catch (e) {
    dispatch(setCurrentUser({}))
    dispatch(logoutHelper({}))
    localStorage.removeItem('SSO-Token')
    deleteCookie('L-Token')
    if (queryString) {
      if (isSso) ssoTokenHelper(queryString)
      else lTokenHelper(queryString)
    }
    window.location.href = `/login`
  }
}

const lTokenHelper = (queryString) => {
  const date = new Date()
  date.setTime(date.getTime() + 60 * 1000)
  document.cookie = `L-Token=${queryString.replace(
    '?L-Token=',
    ''
  )};path=/;expires=${date.toUTCString()};Secure;Domain=${getRootDomain()}`
}

const ssoTokenHelper = (queryString) => {
  const urlSearchParams = new URLSearchParams(queryString)
  const token = urlSearchParams.get('token')
  localStorage.setItem('SSO-Token', token)
}

export const logoutHelper = (data) => {
  return {
    type: 'LOGOUT_USER',
    payload: data,
  }
}

//tokenCheck
export const tokenCheck = (token) => async (dispatch) => {
  const data = await post('/auth/resetCheck', token)
  if (data.code) dispatch(setTokenCheck(true))
  else dispatch(setTokenCheck(false))
}

//tokenCheck
export const setTokenCheck = (status) => {
  return {
    type: 'TOKEN_CHECK',
    payload: status,
  }
}

//error
export const errorGenerator = (error) => {
  return {
    type: 'GET_ERRORS',
    payload: error,
  }
}
