import React from 'react'
import { useTheme } from 'styled-components'

import { useRouter } from 'hooks'
import { AddIcon } from 'assets/icons'
import Navbar from 'components/Layout/Navbar'
import { Breadcrumb, Button, SectionHeading, Text } from 'components/Common'
import {
  $AEHeader,
  $SearchInput,
  $MarginlessLine,
} from '../../AutoEngagement.styled'
import { FlexBox } from 'styledComponent'
import { isIframe } from 'utils/iframeUtils'

const SurveyHeader = (props) => {
  const { history } = useRouter()
  const { spacing } = useTheme()
  return (
    <>
      {!isIframe() ? (
        <>
          <Navbar heading="Surveys" />
          <FlexBox p={spacing.l} justify="space-between" align="center">
            <Breadcrumb label="Surveys" />
          </FlexBox>
          <$MarginlessLine />
        </>
      ) : null}
      <FlexBox justify="flex-end" pt={isIframe() ? spacing.s : '0'}>
        <Button
          mr={spacing.l}
          pr={spacing.xl}
          variant="primary"
          startIcon={AddIcon}
          onClick={() => history.push('/auto-engage/surveys/create')}
        >
          New Survey
        </Button>
      </FlexBox>
      <$MarginlessLine />
      <$AEHeader justify="space-between">
        <span />
        <span>
          <$SearchInput>
            <span />
            <input
              id="searchTxt"
              value={props.search}
              placeholder="Search by Survey Name"
              onChange={(e) => props.setSearch(e.target.value)}
            />
          </$SearchInput>
        </span>
      </$AEHeader>
    </>
  )
}

export default SurveyHeader
