import React from 'react'
import SmileyFilled5Arr, {
  SmileyFilled3Arr,
  SmileyFilled2Arr,
  SmileyFilled10Arr,
} from '../../../images/feedback/smileys/Emojis Filled/'
import SmileyUnFilled5Arr, {
  SmileyUnFilled3Arr,
  SmileyUnFilled2Arr,
  SmileyUnFilled10Arr,
} from 'images/feedback/smileys/Emojis Unfilled'
import Slider15Arr from '../../../images/feedback/Sliders/Slider 1-5/'
import Slider110Arr from '../../../images/feedback/Sliders/Slider 1-10/'
import { NewStarIcon } from 'styledComponent'

const Icons = ({
  starRating,
  scaleType,
  filledRating = null,
  scaleLength,
  handleRatingClick = () => {},
  showAll = false,
}) => {
  const length = showAll ? scaleLength : starRating
  switch (scaleType) {
    case 'star':
      return Array(length)
        .fill(1)
        .map((x, i) => <NewStarIcon className="mx-1" key={i} />)
    case 'smiley':
      return Array(length)
        .fill(1)
        .map((x, i) => (
          <img
            src={
              scaleLength === 10
                ? i <= starRating - 1
                  ? SmileyFilled10Arr[i]
                  : SmileyUnFilled10Arr[i]
                : scaleLength === 3
                ? starRating - 1 === i
                  ? SmileyFilled3Arr[i]
                  : SmileyUnFilled3Arr[i]
                : scaleLength === 2
                ? starRating - 1 === i
                  ? SmileyFilled2Arr[i]
                  : SmileyUnFilled2Arr[i]
                : starRating - 1 === i
                ? SmileyFilled5Arr[i]
                : SmileyUnFilled5Arr[i]
            }
            style={{ height: '24px', padding: '2px' }}
            alt="Rating smiley"
            onClick={(x) => handleRatingClick(i + 1)}
          />
        ))
    case 'bar':
      return Array(length)
        .fill(1)
        .map((x, i) => (
          <img
            src={scaleLength === 5 ? Slider15Arr[i] : Slider110Arr[i]}
            style={{ height: '18px', padding: '1px' }}
            alt="Rating Bar/Slider"
            onClick={(x) => handleRatingClick(i + 1)}
          />
        ))
    case 'customIcon':
      return (
        filledRating &&
        Array(length)
          .fill(1)
          .map((x, i) => (
            <img
              key={i}
              src={filledRating}
              style={{ height: '24px', padding: '3px' }}
              alt="Rating custom-icon"
            />
          ))
      )
    default:
      return <></>
  }
}

export default Icons
