import React, { Component } from 'react'
import { connect } from 'react-redux'
import FileDownload from 'js-file-download'
import swal from 'sweetalert'
import ReactPaginate from 'react-paginate'
import {
  PrimaryOutlineButton,
  PrimaryButton,
  GreyText,
  HorizontalLine,
  Block,
  FilterXIcon,
  ExportIcon,
  CheckBox,
  Text,
  FlexBox,
} from 'styledComponent'
import { Breadcrumb, Dropdown, SearchInput, Skeleton } from 'components/Common'
import Feedback from './Feedback'
import FeedbackDetail from './FeedbackDetail'
import FeedbackFilter from './FeedbackFilter'
import {
  getFeedbacks,
  setFeedbackFilters,
  setFeedbackSort,
  filterBrand,
  filterStores,
  resetFilters,
  exportFeedbacks,
} from 'redux/actions'
import 'css/content/feedback.scss'
import { post } from 'utils/axiosHandler'
import { SortOptions, listRowsPerPage } from 'utils/feedback'
import Navbar from 'components/Layout/Navbar'
import _ from 'lodash'
import { PaginationWrapper } from '../bills/style'
import { SimpleOutlineButton } from 'styledComponent/components/button'
import { StoreHeading } from 'components/Layout/StoresHeader'
import { isIframe } from 'utils/iframeUtils'

class FeedbackList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeFeedbackIndex: null,
      checkedBillUids: [],
      pageNumber: 0,
      isFilterActive: false,
      isListLoading: false,
      isSelectAll: false,
      isExport: false,
      selectedStoreIds: [],
      isPopOpen: false,
    }

    this.filterModalRef = React.createRef()

    this.resetPageNumber = this.resetPageNumber.bind(this)
  }

  async componentDidMount() {
    this.setState({ isListLoading: true })
    await this.props.getFeedbacks()
    this.props.feedbacks.length > 0 && this.setState({ activeFeedbackIndex: 0 })

    this.setState({ isListLoading: false })
  }

  componentWillUnmount() {
    this.resetFeedbackFilters()
  }

  handleAutoCompleteChange = (search) => {
    this.props.setFeedbackFilters({ search })
    this.resetPageNumber()
    setTimeout(() => this.props.getFeedbacks(), 0)
  }

  openFilterModal = (e, isExport = false) => {
    this.filterModalRef.current.openFilterModal()
    this.setState({ isExport })
  }

  handleDropdownSelect = (sortOption) => {
    sortOption && this.props.setFeedbackSort({ ...sortOption })
    setTimeout(() => this.props.getFeedbacks(), 0)
  }

  handleFeedbackClick = (activeFeedbackIndex) => {
    this.setState({ activeFeedbackIndex })
  }

  resetFeedbackFilters() {
    if (!this.props.rememberFilters) this.props.resetFilters()
  }

  handleExportClick = async () => {
    const billUids = this.state.checkedBillUids
    if (!billUids.length)
      return swal({
        title: 'Feedback Not Selected!',
        text: 'Select Feedbacks to Export',
        icon: 'warning',
      })

    const data = await post(
      `/feedback/exportFeedback`,
      {
        billUids,
      },
      {
        responseType: 'arraybuffer',
      }
    )

    var blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    FileDownload(blob, 'feedbacks.xlsx')
  }
  handleExportAllClick = async (x) => {
    const data = await this.props.exportFeedbacks()

    var blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    FileDownload(blob, 'feedbacks.xlsx')
  }

  onFeedbackCheckBoxClick = (action, billUid) => {
    const checkedBillUids = [...this.state.checkedBillUids]
    if (action === 'add') {
      this.setState({ checkedBillUids: [...checkedBillUids, billUid] })
    } else if (action === 'remove') {
      const index = checkedBillUids.indexOf(billUid)
      checkedBillUids.splice(index, 1)
      this.setState({
        checkedBillUids: [...checkedBillUids],
        isSelectAll: false,
      })
    }
  }

  handlePageClick = async (selected, filters = {}) => {
    this.resetPageNumber(selected)
    this.setState({ isListLoading: true })
    await this.props.getFeedbacks({
      offset: selected * listRowsPerPage,
      ...filters,
    })
    this.setState({ isListLoading: false })
  }

  resetPageNumber(selected = 0) {
    this.setState({ pageNumber: selected })
  }

  /* getFilterStatus(){
    Object.keys(this.props.filters).forEach(filter => {
      if(this.props.filters[filter] && this.props.filters[filter].isActive){
        this.setState({ isFilterActive: true })
      }
    })
  } */

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevState?.isPopOpen !== this.state.isPopOpen &&
        !this.state.isPopOpen) ||
      prevProps?.selectedStores !== this.props?.selectedStores
    ) {
      this.props.setFeedbackFilters({
        ...this.props?.filters,
        storeIds: this.state.selectedStoreIds.length
          ? this.state.selectedStoreIds
          : this.props.selectedStores,
      })
      this.props.getFeedbacks({
        storeIds: this.state.selectedStoreIds.length
          ? this.state.selectedStoreIds
          : this.props.selectedStores,
      })
    }
  }

  handleSelectAll = (e) => {
    const action = e.target.checked ? `add` : `remove`
    const checkedBillUids = [...this.state.checkedBillUids]
    if (e.target.checked) {
      const billUids = this.props.feedbacks.map((f) => f.billUid)
      this.setState({
        checkedBillUids: _.uniq([...checkedBillUids, ...billUids]),
        isSelectAll: !this.state.isSelectAll,
      })
    } else {
      this.setState({
        checkedBillUids: [],
        isSelectAll: !this.state.isSelectAll,
      })
    }
  }
  render() {
    const pageCount = Math.ceil(this.props.feedbackCount / listRowsPerPage)
    return (
      <div className="bg-white" style={{ height: '100%' }}>
        {!isIframe() ? <Navbar heading="Feedback" /> : null}
        <Block className="feedback-module p-3">
          <div className="manage-filters">
            <div className="row mb-2">
              <div className="col-md-6 d-inline-flex">
                {!isIframe() ? <Breadcrumb label="Responses" /> : null}
              </div>
              <div className="col-md-6 d-inline-flex justify-content-end">
                <SimpleOutlineButton
                  type="button"
                  className="mr-1 py-2"
                  onClick={() => this.setState({ isPopOpen: true })}
                >
                  <StoreHeading
                    selectedStoreIds={this.state.selectedStoreIds}
                    setSelectedStoreIds={(selectedStoreIds) =>
                      this.setState({ selectedStoreIds })
                    }
                    isPopOpen={this.state.isPopOpen}
                    setIsPopOpen={(isPopOpen) => this.setState({ isPopOpen })}
                  />
                </SimpleOutlineButton>
                <PrimaryOutlineButton
                  type="button"
                  className="d-inline-flex ml-2 pl-4 pr-4 filter-btn"
                  onClick={this.openFilterModal}
                >
                  <FilterXIcon className="mr-2" />
                  Filter
                </PrimaryOutlineButton>
                <PrimaryButton
                  type="button"
                  className="d-inline-flex ml-2 pl-4 pr-4 filter-btn"
                  // onClick={this.handleExportClick}
                  onClick={(e) => this.openFilterModal(e, true)}
                >
                  <ExportIcon className="mr-2" />
                  Export
                </PrimaryButton>
                {/* <PrimaryButton type="button" className="d-inline-flex mr-2 mb-1 pl-4 pr-4" onClick={this.handleExportAllClick}><ExportIcon className="mr-2" />Export All</PrimaryButton> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="pagination d-inline-flex justify-content-between">
                <GreyText variant="caption">
                  Showing {this.props.feedbacks.length} of{' '}
                  {this.props.feedbackCount} items{' '}
                </GreyText>
                <div className="d-inline-flex justify-content-right">
                  <GreyText variant="caption">Sort By</GreyText>
                  <Dropdown
                    options={SortOptions}
                    onChange={this.handleDropdownSelect}
                    selected={this.props.sort}
                  />
                </div>
              </div>
            </div>
          </div>
          <FlexBox className="mt-1">
            <CheckBox
              checked={
                this.state.isSelectAll ||
                this.state.checkedBillUids ===
                  (this.props.feedbacks && this.props.feedbacks.length)
              }
              onClick={this.handleSelectAll}
              id={`select-all`}
              variant="large"
            />
            <Text>Select All</Text>
          </FlexBox>
          <HorizontalLine />
          <div className="row">
            <div className="col-md-6">
              <div
                id="feedbacks"
                className="feedbacks p-2"
                style={{ height: `calc(100vh - 210px)`, overflow: 'auto' }}
              >
                {this.state.isListLoading && (
                  <Skeleton height={110} count={10} />
                )}
                {!this.state.isListLoading &&
                  this.props.feedbacks.length > 0 &&
                  this.props.feedbacks.map((feedback, index) => (
                    <Feedback
                      index={index}
                      key={index}
                      onClick={() => this.handleFeedbackClick(index)}
                      feedback={feedback}
                      onCheckBoxClick={this.onFeedbackCheckBoxClick}
                      checkedBillUids={this.state.checkedBillUids}
                    />
                  ))}
                {!this.state.isListLoading &&
                  this.props.feedbacks.length === 0 && (
                    <GreyText as="p">No Feedback to display</GreyText>
                  )}
                {(this.props.feedbacks.length > 0 &&
                  this.state.pageNumber === 0) ||
                this.state.pageNumber > 0 ? (
                  <PaginationWrapper>
                    <ul className="feedback-pagination notSelectableText">
                      {this.state.pageNumber > 0 && (
                        <li
                          className={`${
                            !this.props.page ? 'disabled' : ''
                          } previous`}
                          onClick={() =>
                            this.handlePageClick(this.state.pageNumber - 1, {
                              storeIds: this.state.selectedStoreIds.length
                                ? this.state.selectedStoreIds
                                : this.props.selectedStores,
                            })
                          }
                        >
                          <a tabIndex="0" role="button" aria-disabled="false">
                            &lt; Previous
                          </a>
                        </li>
                      )}
                      {this.props.feedbacks.length === 10 && (
                        <li
                          className={`${
                            !this.props.feedbacks.length ? 'disabled' : ''
                          } next`}
                          onClick={() =>
                            this.handlePageClick(this.state.pageNumber + 1, {
                              storeIds: this.state.selectedStoreIds.length
                                ? this.state.selectedStoreIds
                                : this.props.selectedStores,
                            })
                          }
                        >
                          <a tabIndex="1" role="button" aria-disabled="false">
                            Next &gt;
                          </a>
                        </li>
                      )}
                    </ul>
                  </PaginationWrapper>
                ) : null}
              </div>
            </div>
            <div className="col-md-6">
              {this.state.isListLoading ? (
                <>
                  <Skeleton height="20%" />
                  <Skeleton height="60%" />
                  <Skeleton height="20%" />
                </>
              ) : (
                <FeedbackDetail
                  activeFeedbackIndex={this.state.activeFeedbackIndex}
                  className="mt-3"
                />
              )}
            </div>
          </div>
        </Block>
        <FeedbackFilter
          ref={this.filterModalRef}
          resetPageNumber={this.resetPageNumber}
          isExport={this.state.isExport}
          storeIds={
            this.state.selectedStoreIds.length
              ? this.state.selectedStoreIds
              : this.props.selectedStores
          }
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    stores: filterStores(state, 'feedback'),
    brands: filterBrand(state, 'feedback'),
    feedbacks: state.feedback.feedbacks,
    filters: state.feedback.filters,
    feedbackCount: state.feedback.feedbackCount,
    hasMoreFeedbacks: state.feedback.hasMoreFeedbacks,
    sort: state.feedback.sort,
    rememberFilters: state.feedback.rememberFilters,
    selectedVendor: state.vendorIds.selectedVendor,
    selectedStores: state.stores?.selectedStores,
  }
}

export default connect(mapStateToProps, {
  getFeedbacks,
  setFeedbackFilters,
  setFeedbackSort,
  resetFilters,
  exportFeedbacks,
})(FeedbackList)
