import moment from 'moment'
import isEqual from 'lodash/isEqual'

import { get, post } from 'utils/axiosHandler'
import { processFilterData } from 'utils/consumerAnalytics'
import * as types from 'redux/types/consumerAnalytics'

/**
 *  AbortController instances
 */
let avgSpendSource
let avgSkuSource
let segmentRetentionSource
let segmentGenderSource
let segmentAgeSource
let segmentRegionSource
let customerListSource

export const setConsumerAnalyticsDate = (selectedDate) => (dispatch) => {
  dispatch({
    type: types.SET_CA_DATE,
    payload: {
      selectedDate,
    },
  })
}

export const getConsumerDevicesStats =
  (data, options) => async (dispatch, getState) => {
    const state = getState()
    const {
      stores: { selectedStores },
      cAnalytics: { selectedDate },
      vendorIds: { selectedVendor },
    } = state

    try {
      const res = await post(
        '/cAnalytics/customer/devices',
        {
          storeIds: selectedStores,
          startDate: moment(selectedDate?.date?.startDate),
          endDate: moment(selectedDate?.date?.endDate),
          vendorId: selectedVendor?.value,
        },
        options
      )

      dispatch({
        type: types.CONSUMER_DEVICES_STATS,
        payload: { deviceData: res },
      })
    } catch (e) {
      console.log('Error:--', e)
    }
  }

export const getConsumerFeedbackStars =
  (data, options) => async (dispatch, getState) => {
    const state = getState()
    const {
      stores: { selectedStores },
      cAnalytics: { selectedDate },
    } = state

    const res = await post(
      '/cAnalytics/feedback/stars',
      {
        storeIds: selectedStores,
        startDate: moment(selectedDate?.date?.startDate),
        endDate: moment(selectedDate?.date?.endDate),
      },
      options
    )

    dispatch({
      type: types.CONSUMER_FEEDBACK_STARS,
      payload: { feedbackStars: res },
    })
  }

export const getTopCustomers =
  (data, options) => async (dispatch, getState) => {
    const state = getState()
    const {
      stores: { selectedStores },
      cAnalytics: { selectedDate },
    } = state

    const res = await post(
      '/cAnalytics/customer/top-list',
      {
        storeIds: selectedStores,
        startDate: moment(selectedDate?.date?.startDate),
        endDate: moment(selectedDate?.date?.endDate),
      },
      options
    )

    dispatch({
      type: types.TOP_CUSTOMERS_LIST,
      payload: { topCustomers: res },
    })
  }

export const getConsumerStats =
  (data, options) => async (dispatch, getState) => {
    const state = getState()
    const {
      stores: { selectedStores },
      cAnalytics: { selectedDate },
    } = state

    const res = await post(
      '/cAnalytics/customer/stats',
      {
        storeIds: selectedStores,
        startDate: moment(selectedDate?.date?.startDate),
        endDate: moment(selectedDate?.date?.endDate),
      },
      options
    )

    dispatch({
      type: types.CONSUMER_STATS,
      payload: { stats: res },
    })
  }

export const getCustomersList =
  (data, options, segmentTypes = []) =>
  async (dispatch, getState) => {
    const state = getState()
    const {
      stores: { selectedStores },
      cAnalytics: { filters, selectedDate },
    } = state

    if (customerListSource) customerListSource.abort()

    customerListSource = new AbortController()
    const signal = customerListSource.signal

    const filter = processFilterData(filters)

    if (Array.isArray(filter.segmentsValue)) {
      segmentTypes = filter.segmentsValue
      delete filter.segmentsValue
    }

    const res = await post(
      '/cust-segmentation/customer/segment/list',
      {
        storeIds: selectedStores,
        startDate: moment(selectedDate?.date?.startDate),
        endDate: moment(selectedDate?.date?.endDate),
        segmentTypes,
        limit: 10,
        skip: data,
        filters: filter,
      },
      {
        ...options,
        signal,
      }
    )

    dispatch({
      type: types.CUSTOMERS_LIST,
      payload: { customersList: res },
    })
  }

export const setCAnalyticsFilters = (data) => async (dispatch) => {
  dispatch({
    type: types.SET_CONSUMER_ANALYTICS_FILTERS,
    payload: { filters: data },
  })
}

export const getConsumerSegmentAvgSpend =
  (data = {}, options) =>
  async (dispatch, getState) => {
    const state = getState()
    const {
      stores: { selectedStores },
      cAnalytics: { selectedDate },
    } = state

    if (avgSpendSource) avgSpendSource.abort()

    avgSpendSource = new AbortController()
    const signal = avgSpendSource.signal

    const res = await post(
      '/cust-segmentation/customer/segment/avgspend',
      {
        storeIds: selectedStores,
        startDate: moment(selectedDate?.date?.startDate),
        endDate: moment(selectedDate?.date?.endDate),
        axisInterval: 'dayOfYear',
        ...data,
      },
      {
        ...options,
        signal,
      }
    )

    dispatch({
      type: types.CONSUMER_SEGMENT_AVG_SPEND,
      payload: { avgSpendSegment: res.data },
    })
  }

export const getConsumerSegmentAvgSku =
  (data = {}, options) =>
  async (dispatch, getState) => {
    const state = getState()
    const {
      stores: { selectedStores },
      cAnalytics: { selectedDate },
    } = state

    if (avgSkuSource) avgSkuSource.abort()

    avgSkuSource = new AbortController()
    const signal = avgSkuSource.signal

    const res = await post(
      '/cust-segmentation/customer/segment/avgsku',
      {
        storeIds: selectedStores,
        startDate: moment(selectedDate?.date?.startDate),
        endDate: moment(selectedDate?.date?.endDate),
        axisInterval: 'dayOfYear',
        ...data,
      },
      {
        ...options,
        signal,
      }
    )

    dispatch({
      type: types.CONSUMER_SEGMENT_AVG_SKU,
      payload: { avgSkuSegment: res.data },
    })
  }

export const getConsumerSegmentRetention =
  (data = {}, options) =>
  async (dispatch, getState) => {
    const state = getState()
    const {
      stores: { selectedStores },
      cAnalytics: { selectedDate },
    } = state

    if (segmentRetentionSource) segmentRetentionSource.abort()

    segmentRetentionSource = new AbortController()
    const signal = segmentRetentionSource.signal

    const res = await post(
      '/cust-segmentation/customer/segment/retention',
      {
        storeIds: selectedStores,
        startDate: moment(selectedDate?.date?.startDate),
        endDate: moment(selectedDate?.date?.endDate),
        axisInterval: 'dayOfYear',
        ...data,
      },
      {
        ...options,
        signal,
      }
    )

    dispatch({
      type: types.CONSUMER_SEGMENT_RETENTION,
      payload: { retentionSegment: res.data },
    })
  }

export const getConsumerSegmentAge =
  (data = {}, options) =>
  async (dispatch, getState) => {
    const state = getState()
    const {
      stores: { selectedStores },
      cAnalytics: { selectedDate },
    } = state

    if (segmentAgeSource) segmentAgeSource.abort()

    segmentAgeSource = new AbortController()
    const signal = segmentAgeSource.signal

    const res = await post(
      '/cust-segmentation/customer/segment/age',
      {
        storeIds: selectedStores,
        startDate: moment(selectedDate?.date?.startDate),
        endDate: moment(selectedDate?.date?.endDate),
        axisInterval: 'dayOfYear',
        ...data,
      },
      {
        ...options,
        signal,
      }
    )

    dispatch({
      type: types.CONSUMER_SEGMENT_AGE,
      payload: { ageSegment: res.data },
    })
  }

export const getConsumerSegmentGender =
  (data = {}, options) =>
  async (dispatch, getState) => {
    const state = getState()
    const {
      stores: { selectedStores },
      cAnalytics: { selectedDate },
    } = state

    if (segmentGenderSource) segmentGenderSource.abort()

    segmentGenderSource = new AbortController()
    const signal = segmentGenderSource.signal

    const res = await post(
      '/cust-segmentation/customer/segment/gender',
      {
        storeIds: selectedStores,
        startDate: moment(selectedDate?.date?.startDate),
        endDate: moment(selectedDate?.date?.endDate),
        axisInterval: 'dayOfYear',
        ...data,
      },
      {
        ...options,
        signal,
      }
    )

    dispatch({
      type: types.CONSUMER_SEGMENT_GENDER,
      payload: { genderSegment: res.data },
    })
  }

export const getSegmentList =
  (data = {}, options) =>
  async (dispatch, getState) => {
    const state = getState()
    const {
      stores: { selectedStores },
      vendorIds: { selectedVendor },
      cAnalytics: { selectedDate },
    } = state

    const res = await post(
      '/cust-segmentation/customer/segment/segment-list',
      {
        vendorId: selectedVendor.value,
        startDate: moment(selectedDate?.date?.startDate),
        endDate: moment(selectedDate?.date?.endDate),
        axisInterval: 'dayOfYear',
        storeIds: selectedStores,
        ...data,
      },
      options
    )

    dispatch({
      type: types.ANALYTICS_SEGMENTS_LIST,
      payload: { segmentsList: res.data },
    })
  }

/* export const setSelectedDate = ({ start, end }) => async (dispatch) => {
  dispatch({
    type: types.SET_SELECTED_DATE_RANGE,
    payload: {
      selectedDate: {
        start,
        end,
      },
    },
  })
} */

export const getCustomerSegmentsCount = () => async (dispatch, getState) => {
  const state = getState()
  const {
    stores: { selectedStores },
    cAnalytics: { selectedDate },
  } = state

  const res = await post('/cust-segmentation/customer/segment/count', {
    storeIds: selectedStores,
    startDate: moment(selectedDate?.date?.startDate),
    endDate: moment(selectedDate?.date?.endDate),
  })

  dispatch({
    type: types.CONSUMER_SEGMENTS_COUNT,
    payload: { customerSegmentsCount: res.data },
  })
}

export const setCAPreviousSelected =
  ({ selectedDate, selectedStores, selectedSegments, tab }) =>
  (dispatch, getState) => {
    const state = getState()
    const {
      cAnalytics: { previousSelected },
    } = state

    if (selectedDate && !isEqual(previousSelected?.date, selectedDate)) {
      dispatch({
        type: types.SET_PREVIOUS_SELECTED,
        payload: {
          previousSelected: {
            ...previousSelected,
            date: selectedDate,
          },
        },
      })

      dispatch(setFetchNewData(true))
    } else if (
      selectedStores &&
      !isEqual(previousSelected.stores, selectedStores)
    ) {
      dispatch({
        type: types.SET_PREVIOUS_SELECTED,
        payload: {
          previousSelected: {
            ...previousSelected,
            stores: selectedStores,
          },
        },
      })

      dispatch(setFetchNewData(true))
    } else if (
      selectedSegments &&
      !isEqual(previousSelected.segments, selectedSegments)
    ) {
      dispatch({
        type: types.SET_PREVIOUS_SELECTED,
        payload: {
          previousSelected: {
            ...previousSelected,
            segments: selectedSegments,
          },
        },
      })

      dispatch(setFetchNewChartData(true))
    } else if (tab && !isEqual(previousSelected.tab, tab)) {
      dispatch({
        type: types.SET_PREVIOUS_SELECTED,
        payload: {
          previousSelected: {
            ...previousSelected,
            tab,
          },
        },
      })

      dispatch(setFetchNewChartData(true))
    }
  }

export const setFetchNewData = (data) => (dispatch, getState) => {
  const state = getState()
  const {
    cAnalytics: { previousSelected },
  } = state

  dispatch({
    type: types.SET_FETCH_NEW_DATA,
    payload: {
      previousSelected: {
        ...previousSelected,
        shouldFetchNewData: data,
      },
    },
  })
}

export const setFetchNewChartData = (data) => (dispatch, getState) => {
  const state = getState()
  const {
    cAnalytics: { previousSelected },
  } = state

  dispatch({
    type: types.SET_FETCH_NEW_DATA,
    payload: {
      previousSelected: {
        ...previousSelected,
        shouldFetchNewChartData: data,
      },
    },
  })
}

export const setSelectedSegments =
  (data = []) =>
  (dispatch) => {
    dispatch({
      type: types.SELECTED_SEGMENTS,
      payload: {
        selectedSegments: [...data],
      },
    })
  }

export const setLocationList = (data) => async (dispatch) => {
  dispatch({
    type: types.SET_LOCATION_LIST,
    payload: {
      allAvailableLocation: {
        list: data,
      },
    },
  })
}

export const getSegmentRegionData = (data) => async (dispatch, getState) => {
  try {
    const state = getState()
    const {
      stores: { selectedStores, stores },
      cAnalytics: { selectedDate },
    } = state

    if (segmentRegionSource) segmentRegionSource.abort()

    segmentRegionSource = new AbortController()
    const signal = segmentRegionSource.signal
    const res = await post(
      '/cust-segmentation/customer/segment/region',
      {
        storeIds: selectedStores,
        startDate: moment(selectedDate?.date?.startDate),
        endDate: moment(selectedDate?.date?.endDate),
        axisInterval: 'dayOfYear',
        ...data,
      },
      {
        signal,
      }
    )

    if (res?.data) {
      const mapData = await _getRegionMapData(res.data, stores)
      res.data.mapData = mapData
    }

    dispatch({
      type: types.SET_SEGMENT_ANALYTICS_REGION,
      payload: { segmentsRegion: res?.data || null },
    })
  } catch (error) {
    dispatch({
      type: types.SET_SEGMENT_ANALYTICS_REGION,
      payload: { segmentsRegion: null },
    })
  }
}

const _getRegionMapData = async (data, stores) => {
  if (!data) return []

  if (data.graph && data.graph?.length) {
    const storeStats = data.graph
      .map(({ storeId, visitCount }) => {
        if (stores[storeId]) {
          return {
            visitCount,
            areaCode: stores[storeId].pincode,
          }
        } else {
          return null
        }
      })
      .filter((value) => value && value.areaCode)
      .reduce((acc, curr) => {
        const duplicateAreaCodeIndex = acc.findIndex(
          (stats) => stats?.areaCode === curr.areaCode
        )
        if (duplicateAreaCodeIndex > -1) {
          acc[duplicateAreaCodeIndex].visitCount += curr.visitCount
        } else {
          acc.push(curr)
        }

        return acc
      }, [])

    if (!storeStats?.length) {
      return []
    }

    try {
      const areaCodes = storeStats.map(({ areaCode }) => areaCode).join(',')

      const coordinatesRes = await get(
        `/location/geo/coordinates?areaCodes=${areaCodes}`
      )

      const coordinates = coordinatesRes?.data || []

      if (coordinates?.length) {
        const storeCoordinateStats = coordinates
          .map((coords) => {
            const currentStoreStats = storeStats.find(
              ({ areaCode }) => areaCode === coords.areaCode
            )
            if (currentStoreStats) {
              const storeCoordinates = [coords.lng, coords.lat]
              return {
                coordinates: storeCoordinates,
                label: `Total Visits: ${currentStoreStats.visitCount}`,
              }
            }
          })
          .filter((value) => value)

        return storeCoordinateStats
      }

      return []
    } catch (error) {
      console.log('error', error)
    }
  }

  if (Array.isArray(data) && data.length) {
    let multiStoreStats = []

    data.forEach((stats) => {
      if (stats.graph && stats.graph?.length) {
        const storeStats = stats.graph
          .map(({ storeId, visitCount }) => {
            if (stores[storeId]) {
              return {
                visitCount,
                areaCode: stores[storeId].pincode,
              }
            } else {
              return null
            }
          })
          .filter((value) => value && value.areaCode)
          .reduce((acc, curr) => {
            const duplicateAreaCodeIndex = acc.findIndex(
              (stats) => stats?.areaCode === curr.areaCode
            )
            if (duplicateAreaCodeIndex > -1) {
              acc[duplicateAreaCodeIndex].visitCount += curr.visitCount
            } else {
              acc.push(curr)
            }

            return acc
          }, [])

        multiStoreStats.push(...storeStats)
      }
    })

    if (multiStoreStats.length) {
      multiStoreStats = multiStoreStats.reduce((acc, curr) => {
        const duplicateAreaCodeIndex = acc.findIndex(
          (stats) => stats?.areaCode === curr.areaCode
        )
        if (duplicateAreaCodeIndex > -1) {
          acc[duplicateAreaCodeIndex].visitCount += curr.visitCount
        } else {
          acc.push(curr)
        }
        return acc
      }, [])

      try {
        const areaCodes = multiStoreStats
          .map(({ areaCode }) => areaCode)
          .join(',')

        const coordinatesRes = await get(
          `/location/geo/coordinates?areaCodes=${areaCodes}`
        )

        const coordinates = coordinatesRes?.data || []

        if (coordinates?.length) {
          const storeCoordinateStats = coordinates
            .map((coords) => {
              const currentStoreStats = multiStoreStats.find(
                ({ areaCode }) => areaCode === coords.areaCode
              )
              if (currentStoreStats) {
                const storeCoordinates = [coords.lng, coords.lat]
                return {
                  coordinates: storeCoordinates,
                  label: `Total Visits: ${currentStoreStats.visitCount}`,
                }
              }
            })
            .filter((value) => value)

          return storeCoordinateStats
        }

        return []
      } catch (error) {
        console.log('error', error)
      }
    }
  }
}

export const getPosTypes = () => async (dispatch, getState) => {
  try {
    const state = getState()
    const {
      vendorIds: { selectedVendor },
      cAnalytics: { posData },
    } = state
    const vendorId = selectedVendor.value

    // if (posData?.vendorId !== vendorId) {
    const res = await get('/cust-segmentation/segments/pos-type/details', {
      vendorId,
    })
    dispatch({
      type: types.SET_POS_DATA,
      payload: { posData: res?.data },
    })
    // }
  } catch (err) {
    dispatch({
      type: types.SET_POS_DATA,
      payload: { posData: null },
    })
  }
}

export const getStatesCities = () => async (dispatch, getState) => {
  try {
    const state = getState()
    const {
      stores: { selectedStores },
    } = state

    const res = await post('/location/store-wise/list', {
      storeIds: selectedStores,
    })
    dispatch({
      type: types.SET_STATES_CITIES,
      payload: { location: res?.data },
    })
  } catch (err) {
    dispatch({
      type: types.SET_STATES_CITIES,
      payload: { location: null },
    })
  }
}

export const exportCustomerReport = async ({ segmentId, ...body }) =>
  await post(`/cust-segmentation/customer/segment/${segmentId}/export`, body)
