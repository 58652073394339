import React from 'react'
import {
  $AEHeader,
  $SearchInput,
  $MarginlessLine,
} from '../AutoEngagement.styled'

import { useTheme } from 'styled-components'
import { useRouter } from 'hooks'

import { AddIcon } from 'assets/icons'
import {
  Button,
  Text,
  SectionHeading,
  Breadcrumb,
  ButtonDropdown,
} from 'components/Common'
import Navbar from 'components/Layout/Navbar'
import { FlexBox } from 'styledComponent'
import { isIframe } from 'utils/iframeUtils'

const CouponHeader = (props) => {
  const { history } = useRouter()
  const { spacing } = useTheme()

  return (
    <>
      {!isIframe() ? (
        <>
          <Navbar heading="Coupon Code Generator" />
          <FlexBox p={spacing.l} justify="space-between" align="center">
            <Breadcrumb label="Coupon Code Generator" />
          </FlexBox>
          <$MarginlessLine />
        </>
      ) : null}
      <FlexBox
        justify="flex-end"
        pt={isIframe() ? spacing.s : '0'}
        pr={isIframe() ? spacing.l : '0'}
      >
        <ButtonDropdown startIcon width="180px" buttonText="New Coupons">
          {[
            { label: 'Generate Coupon', value: 'create' },
            { label: 'Upload Coupon', value: 'upload' },
          ].map((item) => (
            <Text
              key={item.value}
              variant="medium"
              type="grey"
              onClick={() => history.push(`/auto-engage/coupons/${item.value}`)}
            >
              {item.label}
            </Text>
          ))}
        </ButtonDropdown>
      </FlexBox>
      <$MarginlessLine />
      <$AEHeader justify="space-between">
        <span />
        <span>
          <$SearchInput>
            <span />
            <input
              id="searchTxt"
              value={props.search}
              placeholder="Search by Coupon Name"
              onChange={(e) => props.setSearch(e.target.value)}
            />
          </$SearchInput>
        </span>
      </$AEHeader>
    </>
  )
}

export default CouponHeader
