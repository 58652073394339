import React, { useState, useRef } from 'react'
import { cloneDeep } from 'lodash'
import { useTheme } from 'styled-components'
import { FlexBox } from 'styledComponent'
import { connect } from 'react-redux'

import { useRouter } from 'hooks'
import { AddIcon, FilterIcon } from 'assets/icons'
import { INIT_FILTER } from 'components/Content/autoEngagement/constants'
import JourneyFilter from './JourneyFilter'
import {
  $SquareButton,
  $AEHeader,
  $SearchInput,
  $MarginlessLine,
} from 'components/Content/autoEngagement/AutoEngagement.styled'
import {
  ActiveFilterPills,
  Text,
  SectionHeading,
  Button,
  Breadcrumb,
} from 'components/Common'
import { filterToQuery } from 'redux/actions/journeyActions'
import JourneyPriority from '../createJourney/JourneyPriority'
import { isIframe } from 'utils/iframeUtils'

const JourneyHeader = (props) => {
  const filterRef = useRef()
  const { spacing } = useTheme()
  const { history } = useRouter()

  const [isPriorityOpen, setIsPriorityOpen] = useState(false)

  const showModal = () => filterRef.current.openModal()

  const handleRemoveAll = () => props.filterToQuery(INIT_FILTER)

  const handleClosePill = (_, type) => {
    const fil = cloneDeep(props.journey.filterData)
    delete fil[type]
    props.filterToQuery({ ...INIT_FILTER, ...fil })
  }

  const handleFilter = (filter) => {
    props.filterToQuery(filter)
  }

  const pillData = Object.values(props.journey.filterData).filter(
    (fil) => fil.isActive
  )

  return (
    <>
      {!isIframe() ? (
        <>
          <FlexBox p={spacing.l} justify="space-between" align="center">
            <Breadcrumb label="All Journeys" />
          </FlexBox>
          <$MarginlessLine />
        </>
      ) : null}
      <FlexBox
        justify="flex-end"
        px={spacing.l}
        py={spacing.s}
        pt={isIframe() ? spacing.m : spacing.s}
      >
        <Button
          ml={spacing.l}
          startIcon={AddIcon}
          variant="primary"
          onClick={() => history.push('/journey/create')}
        >
          New Journey
        </Button>
        <Button
          ml={spacing.l}
          variant="primaryOutline"
          onClick={() => setIsPriorityOpen(true)}
        >
          Journey Events Priority
        </Button>
      </FlexBox>
      <$MarginlessLine />
      <$AEHeader justify="space-between">
        <span>
          {/* <SectionHeading
            heading="All Journeys"
            subHeading="List of all journeys in your system"
            variant="medium"
            subVariant="small"
          /> */}
          <ActiveFilterPills
            ref={filterRef}
            pillData={pillData}
            handleAdd={showModal}
            handleRemoveAll={handleRemoveAll}
            handleClosePill={handleClosePill}
          />
        </span>
        <span>
          <FlexBox>
            <$SearchInput>
              <span />
              <input
                id="searchTxt"
                value={props.search}
                placeholder="Search by Journey Name"
                onChange={(e) => props.setSearch(e.target.value)}
              />
            </$SearchInput>
            <$SquareButton
              title="Filter"
              isFilter
              onClick={showModal}
              size={40}
            >
              <FilterIcon />
            </$SquareButton>
          </FlexBox>
        </span>
        <JourneyFilter
          ref={filterRef}
          data={props.journey.filterData}
          handleFilter={handleFilter}
        />
      </$AEHeader>
      <JourneyPriority
        isPriorityOpen={isPriorityOpen}
        setIsPriorityOpen={setIsPriorityOpen}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  journey: state.journey,
  vendorId: state.vendorIds.selectedVendor.value,
})

export default connect(mapStateToProps, { filterToQuery })(JourneyHeader)
