import React, { useEffect, useState } from 'react'
import { BrandMeta, Header } from 'styledComponent/components/createCampaign'
import {
  PrimaryButton,
  PrimaryOutlineButton,
  CloseButton,
  AddButton,
  Button,
  FloatingInput,
  CheckBox,
  NewStarIcon,
  Card,
  RoundCard,
  Grid,
  FlexBox,
  GreyText,
  Div,
} from 'styledComponent'
import { ImageButton } from 'builder/editor/common/Common.styled'
import { Pill } from 'styledComponent/components/modal'
import CrossIcon from 'images/close-cross.svg'
import { optionTextLimit, captionTextLimit } from 'utils/feedback'
import { Input } from 'components/Common'
import SmileyFilled5Arr, {
  SmileyFilled2Arr,
  SmileyFilled3Arr,
  SmileyFilled10Arr,
} from '../../../images/feedback/smileys/Emojis Filled/'
import { $BannerContainer } from '../../../builder/survey-builder/Survey.styled'
import { AddBlueIcon, CloseIcon } from 'assets/icons'
import Slider15Arr from '../../../images/feedback/Sliders/Slider 1-5/'
import Slider110Arr from '../../../images/feedback/Sliders/Slider 1-10/'
import UploadModal from 'components/Content/autoEngagement/components/UploadModal'

const CreateCampaignHeader = ({
  brandName,
  brandLogo,
  stores,
  storesId,
  openStoreModal,
  handleRemoveStore,
  handleScreenChange,
  card,
  onPreviewClick,
  handleSave,
  isEdit,
  resetState,
  fbHeading,
  fbBgurl,
  filledRating,
  unfilledRating,
  scaleType,
}) => (
  <Header>
    <div>
      <BrandMeta>
        <span>For</span>
        <br />
        {brandName}
      </BrandMeta>
      <FlexBox wrap="wrap" align="center">
        {stores.map((store, i) => {
          if (i === 4) {
            return (
              <GreyText
                as="a"
                pointer
                margin="0px 15px 0px 10px"
                onClick={openStoreModal}
              >
                +{stores.length - 4} more
              </GreyText>
            )
          } else if (i > 4) return null

          return (
            <Pill>
              <img src={brandLogo} alt={`${brandName} logo`} />
              <span>{store}</span>
              {stores.length > 1 && (
                <CloseButton
                  onClick={(e) => handleRemoveStore(e, storesId[i])}
                />
              )}
            </Pill>
          )
        })}
        <AddButton
          corner="round"
          size="large"
          onClick={openStoreModal}
          bgColor="#3B86FF"
          color="white"
        />
      </FlexBox>
    </div>
    <FlexBox align="center">
      <PrimaryOutlineButton
        onClick={(e) => {
          handleScreenChange(e, 3)
          onPreviewClick(card, filledRating, fbHeading)
        }}
      >
        Preview
      </PrimaryOutlineButton>
      {!isEdit ? (
        <PrimaryButton
          onClick={() =>
            handleSave(
              card,
              false,
              fbHeading,
              fbBgurl,
              filledRating,
              unfilledRating,
              scaleType
            )
          }
          style={{ marginLeft: '15px' }}
        >
          Save Campaign
        </PrimaryButton>
      ) : (
        <PrimaryButton
          onClick={() =>
            handleSave(
              card,
              true,
              fbHeading,
              fbBgurl,
              filledRating,
              unfilledRating,
              scaleType
            )
          }
          style={{ marginLeft: '15px' }}
        >
          Update Campaign
        </PrimaryButton>
      )}
      <Button noPadding onClick={resetState}>
        <img width="10px" height="10px" src={CrossIcon} alt="close" />
      </Button>
    </FlexBox>
  </Header>
)

const CampaignCard = (props) => {
  const firstCard = Number(props.cardIndex) === 1
  const { scaleType, scaleLength, unfilledRating, filledRating } = props
  const getInput = () => {
    return props.values?.map((value, i) => (
      <FloatingInput key={i}>
        <input
          type="text"
          value={value}
          data-index={i}
          onChange={props.handleInputValue}
          maxLength={optionTextLimit}
        />
        <label>Option</label>
      </FloatingInput>
    ))
  }

  const getIcons = () => {
    switch (scaleType.value) {
      case 'star':
        return Array(props.starCount)
          .fill(1)
          .map((x, i) => <NewStarIcon className="mx-1" key={i} />)
      case 'smiley':
        return Array(props.starCount)
          .fill(1)
          .map((x, i) => (
            <img
              src={
                scaleLength.value === 3
                  ? SmileyFilled3Arr[i]
                  : scaleLength.value === 2
                  ? SmileyFilled2Arr[i]
                  : scaleLength.value === 10
                  ? SmileyFilled10Arr[i]
                  : SmileyFilled5Arr[i]
              }
              style={{ height: '24px', padding: '3px' }}
              alt="Rating smiley"
            />
          ))
      case 'bar':
        return Array(props.starCount)
          .fill(1)
          .map((x, i) => (
            <img
              src={scaleLength.value === 5 ? Slider15Arr[i] : Slider110Arr[i]}
              style={{ height: '24px', padding: '3px' }}
              alt="Rating Bar/Slider"
            />
          ))
      case 'customIcon':
        return (
          filledRating &&
          Array(props.starCount)
            .fill(1)
            .map((x, i) => (
              <img
                key={i}
                src={filledRating}
                style={{ height: '24px', padding: '3px' }}
                alt="Rating custom-icon"
              />
            ))
        )
    }
  }

  return (
    <RoundCard direction="column" noDash lightBoxShadow alignSelf="flex-start">
      <Card>
        <span>
          {props.starCount}{' '}
          {scaleType.label + ' ' + props.starCount > 1 ? 's' : null}
        </span>
        {getIcons()}
      </Card>
      <Card padding="custom" customPadding="20px 10px">
        <FloatingInput key={99999}>
          <input
            type="text"
            value={props.caption}
            data-index={props.cardIndex}
            data-type="caption"
            onChange={props.handleInputValue}
            maxLength={captionTextLimit}
          />
          <label>Rating caption</label>
        </FloatingInput>
      </Card>
      <Card direction="column" noDash>
        <CheckBox
          name="previous"
          data-index={props.cardIndex}
          onChange={props.handlePreviousCheckbox}
          checked={props.previousCheckbox.includes(Number(props.cardIndex))}
          disabled={firstCard}
          style={{ marginRight: '10px' }}
          id={`check-${props.cardIndex}`}
          margin="10px 0px"
        >
          Use same as previous
        </CheckBox>
        <div>
          {getInput()}
          {props.values.length < 4 && (
            <AddButton onClick={props.handleAddOption} bgSize="10px" animate>
              <GreyText as="span" pointer>
                Add option
              </GreyText>
            </AddButton>
          )}
        </div>
      </Card>
    </RoundCard>
  )
}

const CreateCampaignBody = ({
  cards,
  handleInputValue,
  handlePreviousCheckbox,
  previousCheckbox,
  handleAddOption,
  scaleType,
  scaleLength,
  unfilledRating,
  filledRating,
}) => (
  <Grid
    columns="repeat(auto-fill, minmax(250px, 330px))"
    columnGap="3em"
    rowGap="2em"
  >
    {cards.map((card, i) => (
      <CampaignCard
        starCount={Number(i + 1)}
        values={card.values}
        caption={card.caption || ''}
        cardIndex={i + 1}
        handleInputValue={(e) => handleInputValue(e, i + 1)}
        handlePreviousCheckbox={handlePreviousCheckbox}
        previousCheckbox={previousCheckbox}
        key={i + 1}
        handleAddOption={(e) => handleAddOption(e, i + 1)}
        scaleType={scaleType}
        scaleLength={scaleLength}
        unfilledRating={unfilledRating}
        filledRating={filledRating}
      />
    ))}
  </Grid>
)

const CreateCampaign = (props) => {
  const scaleTypeOptions = [
    {
      label: 'Star',
      value: 'star',
    },
    {
      label: 'Emoji',
      value: 'smiley',
    },
    {
      label: 'Slider',
      value: 'bar',
    },
    {
      label: 'Custom Icons',
      value: 'customIcon',
    },
  ]
  const scaleLengthOptions = [
    {
      label: '2',
      value: 2,
    },
    {
      label: '3',
      value: 3,
    },
    {
      label: '5',
      value: 5,
    },
    {
      label: '10',
      value: 10,
    },
  ]
  const [card, setCard] = useState([])
  const [previousCheckbox, setPreviousCheckbox] = useState([])
  const [isEditCampaign, setIsEditCampaign] = useState(false)
  const [feedbackHeading, setFeedbackHeading] = useState(
    props?.fbHeading ? props?.fbHeading : ''
  )
  const [popUpOpen, setPopUpOpen] = useState({ show: false })
  const [unfilledRating, setUnfilledRating] = useState(
    props?.unfilledRating ? props?.unfilledRating : ''
  )
  const [filledRating, setFilledRating] = useState(
    props?.filledRating ? props?.filledRating : ''
  )
  const [bgUrl, setBgUrl] = useState(props?.fbBgurl ? props?.fbBgurl : '')
  const [scaleLength, setScaleLength] = useState(
    props.scaleLength
      ? scaleLengthOptions.filter((x) => x.value === props.scaleLength)[0]
      : scaleLengthOptions[0]
  )
  const [scaleType, setScaleType] = useState(
    props.scaleType
      ? scaleTypeOptions.filter((x) => x.value === props.scaleType)[0]
      : scaleTypeOptions[0]
  )
  const defaultCardLines = ['Oh! What went wrong?', "That's great!"]
  const setUrl = (url) => {
    switch (popUpOpen?.key) {
      case 'bgImg':
        setBgUrl(url)
        break
      case 'unFilledImg':
        setUnfilledRating(url)
        break
      case 'filledImg':
        setFilledRating(url)
        break
    }
    setPopUpOpen({ show: false })
  }
  useEffect(() => {
    if (props.ratingModels) {
      const updatedCard = props.ratingModels.map((c, i) => ({
        ...c[i],
        ...props.ratingModels[i],
      }))
      setCard(updatedCard)
      setIsEditCampaign(true)
    }
    // setScaleLength(
    //   props.scaleLength
    //     ? scaleLengthOptions.filter((x) => x.value === props.scaleLength)[0]
    //     : scaleLengthOptions[0]
    // )
    // setScaleType(
    //   props.scaleType
    //     ? scaleTypeOptions.filter((x) => x.value === props.scaleType)[0]
    //     : scaleTypeOptions[0]
    // )
  }, [])

  useEffect(() => {
    // if (card.length >= 3) {
    updateCard()
    // }
  }, [scaleLength, scaleType])

  const updateCard = () => {
    const cardArr = Array(scaleLength.value)
      .fill({ caption: '', values: [] })
      .map((v, i) => {
        return {
          index: i + 1,
          caption: card?.[i]
            ? card[i].caption
            : i <= Math.ceil((scaleLength.value - 1) / 2)
            ? defaultCardLines[0]
            : defaultCardLines[1],
          values: card?.[i] ? card[i].values : [],
          scaleType: scaleType.value,
        }
      })
    setCard(cardArr)
  }

  const handleInputValue = (e, cardIndex) => {
    const inputIndex = Number(e.target.getAttribute('data-index'))
    const inputType = e.target.getAttribute('data-type')
    const copyCardState = [...card]

    if (inputType === 'caption') {
      copyCardState[inputIndex - 1].caption = e.target.value
      setCard(copyCardState)
    }
    const currentCardValues = [...card[cardIndex - 1].values]
    currentCardValues[inputIndex] = e.target.value

    copyCardState[cardIndex - 1] = {
      ...copyCardState[cardIndex - 1],
      values: currentCardValues,
    }
    setCard(copyCardState)
  }

  const handleScaleTypeChange = (selectedOption) => {
    setScaleType(selectedOption)
  }
  const handlePreviousCheckbox = (e) => {
    const cardIndex = Number(e.target.getAttribute('data-index'))
    const copyCheckboxState = [...previousCheckbox]
    const checkboxPresent = previousCheckbox.includes(cardIndex)
    const copyCard = [...card]
    if (e.target.checked && !checkboxPresent) {
      copyCard[cardIndex - 1].values = [...card[cardIndex - 2].values]
      copyCheckboxState.push(cardIndex)
    } else if (checkboxPresent) {
      copyCard[cardIndex - 1].values = ['', '']
      const indexOf = copyCheckboxState.indexOf(cardIndex)
      copyCheckboxState.splice(indexOf, 1)
    }

    setPreviousCheckbox(copyCheckboxState)
    setCard(copyCard)
  }

  const handleAddOption = (_e, cardIndex) => {
    const currentCardValues = [...card[cardIndex - 1].values]
    const copyCardState = [...card]
    const addNewOption = currentCardValues.length < 4

    if (addNewOption) currentCardValues[currentCardValues.length] = ''

    copyCardState[cardIndex - 1] = {
      ...copyCardState[cardIndex - 1],
      values: currentCardValues,
    }
    setCard(copyCardState)
  }

  return (
    <>
      <CreateCampaignHeader
        {...props}
        card={card}
        isEdit={isEditCampaign}
        fbHeading={feedbackHeading}
        fbBgurl={bgUrl}
        filledRating={filledRating}
        unfilledRating={unfilledRating}
        scaleType={scaleType.value}
      />
      <hr />
      {popUpOpen?.show && (
        <UploadModal
          isPopOpen={popUpOpen?.show}
          setIsPopOpen={setPopUpOpen}
          setMedia={({ fileLocation }) => setUrl(fileLocation)}
          showMedias={['image']}
          uploadedFrom="survey"
        />
      )}
      <FlexBox>
        <Div width="100%" padding="8px 16px">
          <Input
            label="Scale Type"
            value={scaleType}
            onChange={handleScaleTypeChange}
            options={scaleTypeOptions}
            variant={'single-select'}
          />
        </Div>

        <Div width="100%" padding="8px 16px">
          <Input
            label="Scale Length"
            value={scaleLength}
            onChange={(e) => setScaleLength(e)}
            options={scaleLengthOptions}
            variant={'single-select'}
          />
        </Div>
      </FlexBox>
      {scaleType.value === 'customIcon' && (
        <FlexBox>
          <Div width="100%" padding="8px 16px" marginTop="10px">
            <span style={{ fontSize: '12px', marginRight: '8px' }}>
              Upload Unfilled Rating Image
              <span style={{ color: 'red' }}> * </span>
            </span>
            {unfilledRating ? (
              <$BannerContainer>
                <img src={unfilledRating} alt="Background" />
                <CloseIcon
                  type="button"
                  onClick={() => setUnfilledRating('')}
                />
              </$BannerContainer>
            ) : (
              <div>
                <ImageButton
                  value="upload"
                  onClick={() =>
                    setPopUpOpen({ show: true, key: 'unFilledImg' })
                  }
                  options={scaleTypeOptions}
                  type="button"
                >
                  <AddBlueIcon /> &nbsp;{' '}
                  <span style={{ fontSize: '1 rem' }}> Add Image </span>
                </ImageButton>
              </div>
            )}
          </Div>
          <Div width="100%" padding="8px 16px" marginTop="10px">
            <span style={{ fontSize: '12px', marginRight: '8px' }}>
              Upload Filled Rating Image
              <span style={{ color: 'red' }}> * </span>
            </span>
            {filledRating ? (
              <$BannerContainer>
                <img src={filledRating} alt="Background" />
                <CloseIcon type="button" onClick={() => setFilledRating('')} />
              </$BannerContainer>
            ) : (
              <div>
                <ImageButton
                  value="upload"
                  onClick={() => setPopUpOpen({ show: true, key: 'filledImg' })}
                  options={scaleTypeOptions}
                  type="button"
                >
                  <AddBlueIcon /> &nbsp;{' '}
                  <span style={{ fontSize: '1 rem' }}> Add Image </span>
                </ImageButton>
              </div>
            )}
          </Div>
        </FlexBox>
      )}
      <FlexBox>
        <Div width="100%" padding="8px 16px" marginTop="10px">
          <span style={{ fontSize: '12px', marginRight: '8px' }}>
            Upload Background Image
          </span>
          {bgUrl ? (
            <$BannerContainer>
              <img src={bgUrl} alt="Background" />
              <CloseIcon type="button" onClick={() => setBgUrl('')} />
            </$BannerContainer>
          ) : (
            <div>
              <ImageButton
                value="upload"
                onClick={() => setPopUpOpen({ show: true, key: 'bgImg' })}
                options={scaleTypeOptions}
                type="button"
              >
                <AddBlueIcon /> &nbsp;{' '}
                <span style={{ fontSize: '1 rem' }}> Add Image </span>
              </ImageButton>
            </div>
          )}
        </Div>

        <Div width="100%" padding="8px 16px">
          <Input
            label="Feedback Heading"
            value={feedbackHeading}
            name="feedback-heading"
            onChange={(e) => setFeedbackHeading(e.target.value)}
            options={scaleLengthOptions}
            variant="input"
            type="text"
          />
        </Div>
      </FlexBox>
      <CreateCampaignBody
        cards={card}
        handleInputValue={handleInputValue}
        handlePreviousCheckbox={handlePreviousCheckbox}
        previousCheckbox={previousCheckbox}
        handleAddOption={handleAddOption}
        scaleType={scaleType}
        scaleLength={scaleLength}
        unfilledRating={unfilledRating}
        filledRating={filledRating}
      />
    </>
  )
}
export default CreateCampaign
