import React, { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'
import { cloneDeep } from 'lodash'

import { Div, FlexBox } from 'styledComponent'
import { Steps, useToasts, Button } from 'components/Common'
import AdvertBuilder from 'components/Content/autoEngagement/advert/AdvertBuilder'
import { $CampaignContainer } from 'components/Content/autoEngagement/AutoEngagement.styled'
import BannerBuilder from 'components/Content/autoEngagement/banner/BannerBuilder'
import { CampaignDetails } from 'components/Content/autoEngagement/components/CampaignDetails'
import { EmailMessage } from 'components/Content/autoEngagement/email/EmailMessage'
import PdfUpload from 'components/Content/autoEngagement/sellBelowbill/PdfUpload'
import SmsMessage from 'components/Content/autoEngagement/sms/SmsMessage'
import { SIBBuilder } from 'components/Content/autoEngagement/surveyInBill/SIBBuilder'
import SurveySelect from 'components/Content/autoEngagement/surveyInBill/SurveySelect'
import {
  GET_ACTION_STEPS,
  GET_ACTION_TITLE,
  GET_ACTION_TYPE,
  JOURNEY_IFRAME_POSITION_STYLES,
  JOURNEY_NATIVE_WEB_POSITION_STYLES,
} from '../../constants'
import {
  createJourneyCampaign,
  getJourneyCampaign,
  setLoadJourney,
  updateJourneyCampaign,
} from 'redux/actions/journeyActions'
import { TopActionBar } from 'components/Content/autoEngagement/components/TopActionBar'
import {
  DIFF_OBJ,
  ERROR_DECODE,
} from 'components/Content/autoEngagement/constants'
import { useDispatch } from 'react-redux'
import { SMSTemplate } from 'components/Content/autoEngagement/sms/SMSTemplate'
import PopupMessage from 'components/Content/autoEngagement/popupOverBill/PopupMessage'
import { PopupSettings } from 'components/Content/autoEngagement/popupOverBill/PopupSettings'
import { useDebouncedEffect, useSelectStore } from 'hooks'
import WhatsAppTemplate from 'components/Content/autoEngagement/WhatsApp/Campaigns/Template'
import WhatsAppMessage from 'components/Content/autoEngagement/WhatsApp/Campaigns/Message'
import { isIframe } from 'utils/iframeUtils'

export const JourneyAction = ({ nodeData, jbDetails, onExitJourney }) => {
  const { vendorId, createdBy, companyId, brandId, storeIds } = jbDetails
  const name = [
    'action_sendSMS',
    'action_sendEmail',
    'action_sendWhatsApp',
  ].includes(nodeData?.data?.nodeId)
    ? ''
    : GET_ACTION_TYPE(nodeData?.data?.nodeId) + nodeData?.data?.id

  const initCampaign = {
    name,
    vendorId,
    createdBy,
    companyId,
    type: GET_ACTION_TYPE(nodeData?.data?.nodeId),
    status: 'draft',
  }
  if (
    !['sms', 'email', 'whatsApp'].includes(
      GET_ACTION_TYPE(nodeData?.data?.nodeId)
    )
  ) {
    initCampaign.targetGroup = {
      brandId,
      storeIds,
      type: 'stores',
    }
  }

  const [steps, setSteps] = useState(
    cloneDeep(GET_ACTION_STEPS(nodeData?.data?.nodeId))
  )
  const [resData, setResData] = useState({})
  const [isPublishReady, setIsPublishReady] = useState(false)
  const [campaign, setCampaign] = useState(initCampaign)

  const {
    data: { storeIds: journeyStoreIds },
  } = useSelectStore('journey.jbDetails')

  const { spacing, colors } = useTheme()
  const { toast } = useToasts()
  const dispatch = useDispatch()

  const getCampaign = async () => {
    try {
      if (nodeData?.data?.campaignId) {
        const res = await getJourneyCampaign(nodeData?.data?.campaignId)
        if (res?.data) {
          setResData(res.data)
          setCampaign(res.data)
        } else {
          throw { message: 'Something went wrong!' }
        }
      }
    } catch (err) {
      toast(ERROR_DECODE(err), 'error')
    } finally {
      dispatch(setLoadJourney(false))
    }
  }

  useEffect(() => {
    dispatch(setLoadJourney(true))
    getCampaign()
    return () => {
      setCampaign({})
      setSteps({})
    }
  }, [])

  useDebouncedEffect(
    () => {
      switch (campaign.type) {
        case 'surveyInBill': {
          const { surveyId, surveyButtonId } = campaign?.content?.body || {}
          return setIsPublishReady(!!(surveyId && surveyButtonId))
        }
        case 'email': {
          const isEmailFilled =
            campaign?.name &&
            (campaign?.senderDetails?.name || campaign?.senderDetails?.id) &&
            campaign?.content?.body?.html &&
            campaign?.content?.body?.compressedValue &&
            campaign?.content?.subject
          return setIsPublishReady(!!isEmailFilled)
        }
        case 'sms': {
          let isSMSFilled =
            campaign?.name &&
            campaign?.contentType &&
            campaign?.senderDetails?.name &&
            campaign?.content?.body?.templateId &&
            steps?.[2]?.status === 'active'

          const array = campaign?.content?.body?.templateParams || []
          if (array?.length) {
            const check =
              array.length === Object.values(array).length &&
              !array.includes(undefined) &&
              !array.includes(null)
            if (!check) isSMSFilled = false
          }
          return setIsPublishReady(!!isSMSFilled)
        }

        case 'whatsApp': {
          let isWhatsAppFilled =
            campaign?.name &&
            campaign?.content?.body?.templateId &&
            steps?.[2]?.status === 'active'

          const array = campaign?.content?.body?.templateParams || []
          if (array?.length) {
            const check =
              array.length === Object.values(array).length &&
              !array.includes(undefined) &&
              !array.includes(null)
            if (!check) isWhatsAppFilled = false
          }
          return setIsPublishReady(!!isWhatsAppFilled)
        }
        case 'popupOverBill': {
          const isPopFilled =
            campaign?.name &&
            campaign?.content?.body?.assetsDetails?.length > 0 &&
            campaign?.content?.body?.componentType
          return setIsPublishReady(!!isPopFilled)
        }
        default: {
          const { assetsDetails = [], componentType } =
            campaign?.content?.body || {}
          return setIsPublishReady(
            !!(componentType && assetsDetails?.length > 0)
          )
        }
      }
    },
    [campaign, steps],
    100
  )

  const onStepClick = (step) => {
    const newSteps = [...cloneDeep(GET_ACTION_STEPS(nodeData?.data?.nodeId))]
    if (step.id === 1) {
      newSteps[0].status = 'active'
      newSteps[1].status = 'inactive'
      if (['sms', 'whatsApp'].includes(campaign.type)) {
        newSteps[2].status = 'inactive'
      }
    } else if (step.id === 2) {
      switch (campaign.type) {
        case 'surveyInBill': {
          if (!campaign?.content?.body?.surveyId)
            return toast('Please select a survey', 'error')
          break
        }
        case 'email': {
          if (!campaign?.name)
            return toast('Please enter campaign name.', 'error')
          if (!(campaign?.senderDetails?.name || campaign?.senderDetails?.id))
            return toast('Please enter sender details.', 'error')
          break
        }
        case 'sms': {
          if (!campaign?.name)
            return toast('Please enter campaign name.', 'error')
          if (!campaign?.contentType)
            return toast('Please choose content type.', 'error')
          newSteps[2].status = 'inactive'
          break
        }
        case 'whatsApp': {
          if (!campaign?.name)
            return toast('Please enter campaign name.', 'error')
        }
      }
      newSteps[0].status = 'complete'
      newSteps[1].status = 'active'
    } else if (step.id === 3) {
      if (campaign.type === 'sms' && !campaign?.senderDetails?.name) {
        return toast(`Please enter Header`, 'error')
      }
      if (!campaign?.content?.body?.templateId)
        return toast(`Please enter Template`, 'error')
      newSteps[0].status = 'complete'
      newSteps[1].status = 'complete'
      newSteps[2].status = 'active'
    }
    setSteps(cloneDeep(newSteps))
  }

  const onExit = async () => {
    let campaignId = nodeData?.data?.campaignId
    try {
      let res = {}
      if (campaignId) {
        const filterBody = DIFF_OBJ(campaign, resData)
        if (Object.keys(filterBody).length > 0) {
          res = await updateJourneyCampaign(campaignId, {
            ...filterBody,
            type: GET_ACTION_TYPE(nodeData?.data?.nodeId),
            vendorId,
            journeyStoreIds,
          })
        }
      } else {
        if (!isPublishReady) {
          throw 'Campaign could not be created due to incomplete data.'
        }
        res = await createJourneyCampaign({ ...campaign, journeyStoreIds })
      }
      if (res?.data) {
        campaignId = res.data?._id
      }
    } catch (error) {
      if (
        error?.response?.data?.message === 'invalid campaign content provided'
      ) {
        error =
          'Something went wrong!. Please enter all the necessary campaign details before exiting.'
      }
      toast(ERROR_DECODE(error), 'error')
    } finally {
      onExitJourney(campaignId)
    }
  }

  const props = {
    campaign,
    nodeData,
    setCampaign,
    steps,
    onStepClick,
  }

  const currentStep = Number(
    steps?.filter((st) => st.status === 'active')[0]?.id || 1
  )
  const showPrevious = currentStep != 1
  const showNext = currentStep < steps.length

  const onPreviousClick = () =>
    onStepClick(GET_ACTION_STEPS(nodeData?.data?.nodeId)[currentStep - 2])
  const onNextClick = () =>
    onStepClick(GET_ACTION_STEPS(nodeData?.data?.nodeId)[currentStep])

  const extras = (
    <>
      <FlexBox gap={spacing.l}>
        {steps?.length > 1 && (
          <>
            {showPrevious && (
              <Button variant="primaryOutline" onClick={onPreviousClick}>
                Previous Step
              </Button>
            )}
            {showNext && (
              <Button variant="primary" onClick={onNextClick}>
                Next Step
              </Button>
            )}
          </>
        )}
        {
          <Button disabled={!isPublishReady} variant="publish" onClick={onExit}>
            Save & Exit
          </Button>
        }
      </FlexBox>
    </>
  )

  const positionStyles = isIframe()
    ? JOURNEY_IFRAME_POSITION_STYLES
    : JOURNEY_NATIVE_WEB_POSITION_STYLES

  return (
    <Div
      width="calc(100vw - 100px)"
      minHeight="calc(100vh - 60px)"
      position="absolute"
      zIndex="10"
      left="85px"
      top="60px"
      backgroundColor={colors.white}
      {...positionStyles}
    >
      <TopActionBar
        exitTitle="Save & Exit"
        onExit={onExit}
        title={GET_ACTION_TITLE(nodeData?.data?.nodeId)}
        extras={extras}
      />
      <Div p={spacing.l}>{getBody(nodeData?.data?.nodeId, props)}</Div>
    </Div>
  )
}

const getBody = (nodeId, props) => {
  switch (nodeId) {
    case 'action_sendSMS':
      return <SMSAction {...props} />

    case 'action_sendWhatsApp':
      return <WhatsAppAction {...props} />

    case 'action_sendEmail':
      return <EmailAction {...props} />

    case 'filter_survey':
      return <SIBAction {...props} />

    case 'filter_adBelow':
      return <ABBAction {...props} />

    case 'filter_sellBelow':
      return <SBBAction {...props} />

    case 'filter_banner':
      return <BIBAction {...props} />

    case 'filter_popOver':
      return <PopOverAction {...props} />

    default:
      return ''
  }
}

export const SMSAction = ({ campaign, setCampaign, steps, onStepClick }) => {
  return (
    <Div>
      <Steps distance="200px" steps={steps} stepClick={onStepClick} />
      <$CampaignContainer>
        {steps[0].status === 'active' && (
          <CampaignDetails
            showContent
            campaignType="SMS"
            form={campaign}
            setForm={setCampaign}
          />
        )}
        {steps[1].status === 'active' && (
          <SMSTemplate form={campaign} setForm={setCampaign} />
        )}
        {steps[2].status === 'active' && (
          <SmsMessage form={campaign} setForm={setCampaign} fromJourney />
        )}
      </$CampaignContainer>
    </Div>
  )
}

export const WhatsAppAction = ({
  campaign,
  setCampaign,
  steps,
  onStepClick,
}) => {
  return (
    <Div>
      <Steps distance="200px" steps={steps} stepClick={onStepClick} />
      <$CampaignContainer>
        {steps[0].status === 'active' && (
          <CampaignDetails
            campaignType="WhatsApp"
            form={campaign}
            setForm={setCampaign}
          />
        )}
        {steps[1].status === 'active' && (
          <WhatsAppTemplate form={campaign} setForm={setCampaign} />
        )}
        {steps[2].status === 'active' && (
          <WhatsAppMessage form={campaign} setForm={setCampaign} fromJourney />
        )}
      </$CampaignContainer>
    </Div>
  )
}

export const EmailAction = ({ campaign, setCampaign, steps, onStepClick }) => {
  return (
    <Div>
      <Steps distance="200px" steps={steps} stepClick={onStepClick} />
      <$CampaignContainer>
        {steps[0].status === 'active' ? (
          <CampaignDetails
            isTemplate
            campaignType="E-mail"
            form={campaign}
            setForm={setCampaign}
          />
        ) : (
          <EmailMessage form={campaign} setForm={setCampaign} fromJourney />
        )}
      </$CampaignContainer>
    </Div>
  )
}

export const SBBAction = ({ campaign, setCampaign }) => {
  return (
    <$CampaignContainer>
      <PdfUpload isJourney form={campaign} setForm={setCampaign} />
    </$CampaignContainer>
  )
}

export const ABBAction = ({ campaign, setCampaign }) => {
  return (
    <$CampaignContainer>
      <AdvertBuilder form={campaign} setForm={setCampaign} />
    </$CampaignContainer>
  )
}

export const BIBAction = ({ campaign, setCampaign }) => {
  return (
    <$CampaignContainer>
      <BannerBuilder form={campaign} setForm={setCampaign} />
    </$CampaignContainer>
  )
}

export const SIBAction = ({ campaign, setCampaign, steps, onStepClick }) => {
  return (
    <Div>
      <Steps distance="200px" steps={steps} stepClick={onStepClick} />
      <$CampaignContainer>
        {steps[0].status === 'active' ? (
          <SurveySelect form={campaign} setForm={setCampaign} />
        ) : (
          <SIBBuilder form={campaign} setForm={setCampaign} />
        )}
      </$CampaignContainer>
    </Div>
  )
}

export const PopOverAction = ({
  campaign,
  setCampaign,
  steps,
  onStepClick,
}) => {
  return (
    <Div>
      <Steps distance="200px" steps={steps} stepClick={onStepClick} />
      <$CampaignContainer>
        {steps[0].status === 'active' && (
          <PopupMessage form={campaign} setForm={setCampaign} />
        )}
        {steps[1].status === 'active' && (
          <PopupSettings form={campaign} setForm={setCampaign} />
        )}
      </$CampaignContainer>
    </Div>
  )
}
