import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react'
import styled, { css, keyframes } from 'styled-components'
import { spacing, borderRadius } from 'design-system/styles'

const POSITION = {
  TOP_LEFT: 'top-left',
  TOP_RIGHT: 'top-right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_RIGHT: 'bottom-right',
}

const getToastBackground = (type, theme) => {
  return theme.colors.toast[type]?.background || theme.colors.light
}

const getToastColor = (type, theme) => {
  return theme.colors.toast[type]?.color || ''
}

const StyledToastContainer = styled.div`
  width: max-content;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.transparent};
  position: fixed;
  margin: auto;
  // z-index set to 1000 to display toast on AddSegmentModal component which itself has z-index set to 1000
  z-index: 1000;

  top: 25px;
  left: 50%;
  transform: translate(-50%);
  bottom: unset;

  ${(props) =>
    props.position === POSITION.TOP_LEFT &&
    css`
      top: 25px;
      left: 50px;
      right: unset;
      bottom: unset;
      transform: unset;
    `}

  ${(props) =>
    props.position === POSITION.TOP_RIGHT &&
    css`
      top: 25px;
      right: 25px;
      left: unset;
      bottom: unset;
      transform: unset;
    `}

    ${(props) =>
    props.position === POSITION.BOTTOM_LEFT &&
    css`
      top: unset;
      bottom: 25px;
      left: 25px;
      right: unset;
      transform: unset;
    `}

    ${(props) =>
    props.position === POSITION.BOTTOM_RIGHT &&
    css`
      top: unset;
      right: 80px;
      bottom: 25px;
      left: unset;
      transform: unset;
    `}
`

const animate = keyframes`
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
`

const StyledToast = styled.div`
  background-color: ${({ theme }) => theme.colors.light};
  padding: ${spacing.m} ${spacing.l};
  border-radius: ${borderRadius.small};
  font-size: 0.9em;
  min-width: 180px;
  width: max-content;
  text-align: center;
  margin: ${spacing.s};
  animation: ${animate} 230ms ease-in-out;

  ${(props) =>
    props.type &&
    css`
      background-color: ${getToastBackground(props.type, props.theme)};
      color: ${getToastColor(props.type, props.theme)};
    `}
`

export const ToastContext = createContext()

const Toast = ({ children, close, type }) => {
  const removeRef = useRef()
  removeRef.current = close

  useEffect(() => {
    const id = setTimeout(() => removeRef.current(), 5000)

    return () => clearTimeout(id)
  }, [])

  return <StyledToast type={type}>{children}</StyledToast>
}

let count = 0

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([])
  const [position, setPosition] = useState('')

  const toast = (message, type, position) => {
    const id = count++
    const toast = { message, type, position, id }

    setToasts([...toasts, toast])
    toastPosition(position)
  }

  const removeToast = (id) =>
    setToasts(toasts.filter((toast) => toast.id !== id))

  const toastPosition = (position) => setPosition(position)

  return (
    <ToastContext.Provider value={{ toast, removeToast }}>
      {children}
      <StyledToastContainer position={position}>
        {toasts.map(({ message, type, position, id }) => (
          <Toast key={id} close={() => removeToast(id)} type={type}>
            {message}
          </Toast>
        ))}
      </StyledToastContainer>
    </ToastContext.Provider>
  )
}

export const useToasts = () => useContext(ToastContext)

// =====Reference=====
// -> https://codesandbox.io/s/react-toast-notifications-hooks-f3ih4
// -> https://stackblitz.com/edit/react-zdjfv5?file=Toast%2FToast.js
// ===================
